import * as React from 'react'
import { withPrismicUnpublishedPreview } from 'gatsby-plugin-prismic-previews'
import { PrismicRichText, PrismicLink } from '@prismicio/react'

const ThankYou = (lang) => (
  <div className="Container">
    <div className="thankyou">
      <h1>Merci de nous contacter!</h1>
      <h3>Nous vous répondrons dans les 72 heures.</h3>
      <p>
        <PrismicLink href="/fr-ca/contact">
          <button type="button" className="btn_purple">Revenir au contact</button>
        </PrismicLink>
      </p>
    </div>
  </div>
)

export default withPrismicUnpublishedPreview(ThankYou)
